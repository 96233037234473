.RIBASSISTA {
    color: #333 !important;
    background-color: #FF0000 !important;
}

.RAFFORZAMENTO {
    color: #006100 !important;
    background-color: #C6EFCE !important;
}

.RIALZISTA {
    color: #333 !important;
    background-color: #00B050 !important;
}

.INDEBOLIMENTO {
    color: #9C0006 !important;
    background-color: #FFC7CE !important;
}