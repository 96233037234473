.App {
  height: 100vh;
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: 70px 70px 1fr 40px;
  grid-template-areas: "header header" "service service" "content content" "footer footer";
  justify-items: stretch;
}

.grid-header {
  grid-area: header;
}

.grid-footer {
  grid-area: footer;
}

.grid-content {
  grid-area: content;
}

.grid-service {
  grid-area: service;
}

.half-image {
  height: 50px;
}

.fix-top {
  position: sticky;
  top: 0px;
}

.p90 {
  font-size: 90%;
}

.p80 {
  font-size: 80%;
}

.p70 {
  font-size: 70%;
}

.p60 {
  font-size: 60%;
}

.p50 {
  font-size: 50%;
}

.p40 {
  font-size: 40%;
}

.p30 {
  font-size: 30%;
}

.p20 {
  font-size: 20%;
}

.p10 {
  font-size: 10%;
}

.z-index-front {
  z-index: 2000 !important;
}

.grid-row-start-1 {
  grid-row-start: 1;
}

.grid-row-start-2 {
  grid-row-start: 2;
}

.grid-row-start-3 {
  grid-row-start: 3;
}

.grid-row-start-4 {
  grid-row-start: 4;
}

.grid-row-end-1 {
  grid-row-end: 1;
}

.grid-row-end-2 {
  grid-row-end: 2;
}

.grid-row-end-3 {
  grid-row-end: 3;
}

.grid-row-end-4 {
  grid-row-end: 4;
}

.grid-column-start-1 {
  grid-column-start: 1;
}

.grid-column-start-2 {
  grid-column-start: 2;
}

.grid-column-start-3 {
  grid-column-start: 3;
}

.grid-column-end-1 {
  grid-column-end: 1;
}

.grid-column-end-2 {
  grid-column-end: 2;
}

.grid-column-end-3 {
  grid-column-end: 3;
}

.w-30rem {
  min-width: 30rem !important;
}

.grid-dashboard-content {
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: auto 1fr;
  grid-template-areas: "draw search" "draw legend";
  min-height: 100%;
}

.grid-draw {
  grid-area: draw;
  min-height: 100%;
  min-width: 100%;
}

.grid-search {
  grid-area: search;
}

.grid-legend {
  grid-area: legend;
  overflow-y: scroll;
}

.w-5rem {
  width: 5rem !important;
}

#draw-area {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
}

#graph {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-row-start: 3;
  grid-column-end: 3;
}

.background-hilight {
  background-color: greenyellow;
  width: 100%;
  height: 100%;
}

.sticky-2nd-level {
  position: sticky !important;
  top: 50px;
  z-index:1999;
}

.z-index-modal {
  z-index:2048 !important;
}

.scrollable-menu {
  height: auto;
  max-height: 50vh;
  overflow-x: hidden;
}

.grid-gas {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.grid-qbuttons {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-columns: 50% 50%;
  padding: 0px;
  margin: 0px;
  width: 3rem;
  height: 3rem;
  column-gap: 1px;
  row-gap: 1px;
}


.grid-qbuttons :nth-child(1) {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 2;
}

.grid-qbuttons :nth-child(2) {
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-column-end: 3;
}

.grid-qbuttons :nth-child(3) {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 2;
}

.grid-qbuttons :nth-child(4) {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-row-end: 3;
  grid-column-end: 3;
}

.fixed-width-1em {
  display: inline-block;
  width: 1em !important;
}
.fixed-width-3em {
  display: inline-block;
  width: 3em !important;
}

.fixed-width-5em {
  display: inline-block;
  width: 5em !important;
}

.fixed-width-7em {
  display: inline-block;
  width: 7em !important;
}

.fixed-width-9em {
  display: inline-block;
  width: 9em !important;
}