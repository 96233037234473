#draw-area {
    display: grid;
    grid-gap: 0px 0px;
    grid-template-columns: 30px auto 30px;
    grid-template-rows: 30px auto 30px;
    border: 1px solid teal;
}
#tl {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
    overflow: hidden;
}
#tr {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: 2;
    grid-column-end: 4;
    overflow: hidden;
}
#bl {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-column-end: 2;
    overflow: hidden;
}
#br {
    grid-row-start: 3;
    grid-column-start: 3;
    grid-row-end: 4;
    grid-column-end: 4;
    overflow: hidden;
}
#tcb {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 3;
    overflow: hidden;
}
#ttcb {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 3;
    color: #333;
    font-weight: bold;
    font-size: 1em;
    overflow: hidden;
    z-index: 100;
}

#lcb {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
    overflow: hidden;
}
#tlcb {
    color: #333;
    font-weight: bold;
    font-size: 1em;
    z-index: 100;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
    overflow: hidden;
}
#rcb {
    grid-row-start: 2;
    grid-column-start: 3;
    grid-row-end: 3;
    grid-column-end: 4;
    overflow: hidden;
}
#trcb {
    color: #333;
    font-weight: bold;
    font-size: 1em;
    z-index: 100;
    grid-row-start: 2;
    grid-column-start: 3;
    grid-row-end: 3;
    grid-column-end: 4;
    overflow: hidden;
}
#bcb {
    grid-row-start: 3;
    grid-column-start: 2;
    grid-row-end: 4;
    grid-column-end: 3;
    overflow: hidden;
}
#tbcb {
    color: #333;
    font-weight: bold;
    font-size: 1em;
    z-index: 100;
    grid-row-start: 3;
    grid-column-start: 2;
    grid-row-end: 4;
    grid-column-end: 3;
    overflow: hidden;
}
#graph {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    overflow: hidden;
    position: relative;
}
.minifont {
    font-size: 80%;
}
#nav-params {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 3;
    overflow: hidden;
    border-bottom: 1px solid #333;
}
#nav-params ul {
    margin:0px;
    padding:0.5rem;
    display: flex;
    flex-direction: row;
}
#nav-params li {
    list-style: none;
    display:inline;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
}
#listContainer {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-row-end: 3;
    grid-column-end: 3;
    overflow-y: scroll;
    overflow-x: hidden;
    list-style: none;
    padding: 0px;
    padding-left: 10px;
}
#listContainer li {
    list-style: none;
    cursor: pointer;
    margin-bottom: 2px;
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    align-content: stretch;
    column-gap: 1em;
}
#listContainer li div:hover {
    background-color: #fef;
}
#listContainer li div {
    padding: 2px;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px #ccc solid;
    border-radius: 2px;
    background-color: #eee;
}
#listContainer li div:nth-child(1) {
    flex-grow: 9; 
}
input[type=range] {
    vertical-align: bottom;
}
.inner {
    position: relative;
    top: 50%;
    left: 50%;
    width: 60rem;
    text-align: center;
}
.vertical {
    transform: translate(-50%, -50%) rotate(-90deg);
}
.horizzontal {
    transform: translate(-50%, -50%);
}
.label {
    background-color:antiquewhite;
    padding: 2px;
    border: solid 1px #333;
    border-radius: 2px;
    font-size: 0.5rem;
    text-align: center;
    width: 6rem;
    z-index: 100;
}
#q1 {
    position: absolute;
    right:15px;
    top:15px;
}
#q2 {
    position: absolute;
    left:15px;
    top:15px;
}
#q3 {
    position: absolute;
    bottom: 15px;
    left: 15px;
}
#q4 {
    position: absolute;
    bottom: 15px;
    right: 15px;
}
.hand {
    cursor: pointer;
}
#helpOverlay {
    background-color: rgba(3,3,3,0.8);
    display: grid;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    z-index: 200;
    color: #eee;
    display:none;
    grid-template-columns: auto 300px;
    grid-template-rows: 50px auto;
}
#helpOverlay strong {
    color: #2986cc;
}
#helpOverlay>* {
    font-size: 1rem;
}
#helpTopBar {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    border: yellow dashed 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#helpTopBar>ul {
    display: inline;
    margin: auto;
}
#helpTopBar>ul>li {
    padding: 0px;
    display: inline;
}
#helpGraphArea {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    text-align: center;
    padding-top: 25%;
    border: yellow dashed 3px;
}
#helpInstrumentsList {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    padding: 0.5rem;
    padding-top: 100%;
    border: yellow dashed 3px;
}
#helpClose {
    position: relative;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    z-index: 300;
}

#helpClose>* {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    color: #333;
    text-decoration: none;
    padding: 2px;
    background-color: #afa;
    margin-top: 5px;
    margin-right: 5px;
}
#helpButton {
    margin-left: auto;
    cursor: pointer;
    padding: 2px;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px #ccc solid;
    border-radius: 2px;
    background-color: #eee;
}
#helpButton:hover {
    background-color: #fef;
}
.loader {
    border: 8px solid rgba(3,3,3,0);
    border-top: 8px solid #3498db; 
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#loadingOverlay {
    background-color: rgba(3,3,3,0.8);
    display: none;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    z-index: 300;
    color: #eee;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.rsmall {
    margin: 0px;
    padding: 0px;
    width: 100%
}

.rsmall button {
    border-radius: 0px;
    border-style: solid;
    width: 48%;
}
#graphOverlay {
    background-color: white;
    display: grid;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    z-index: 200;
    color: #eee;
    display:none;
    grid-template-rows: 2em auto;
    grid-template-columns: auto;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;

}
#tvGraph {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
}

.dt-crosstalk-fade {
    opacity: 0.2;
}


table.dataTable {
    display: table;
}

table.dataTable {
    width:100%;
    margin:0 auto;
    clear:both;
    border-collapse:separate;
    border-spacing:0
}
table.dataTable thead th,table.dataTable tfoot th{
    font-weight:bold
}
table.dataTable thead th,table.dataTable thead td{
    padding:10px 18px;
    border-bottom:1px solid #111
}
table.dataTable thead th:active,table.dataTable thead td:active{outline:none}
table.dataTable tfoot th,table.dataTable tfoot td{padding:10px 18px 6px 18px;border-top:1px solid #111}
table.dataTable thead .sorting,table.dataTable thead .sorting_asc,table.dataTable thead .sorting_desc,table.dataTable thead .sorting_asc_disabled,table.dataTable thead .sorting_desc_disabled{
    cursor:pointer;
    *cursor:hand;
    background-repeat:no-repeat;
    background-position:center right
}
table.dataTable thead .sorting{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7XQMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC)
}
table.dataTable thead .sorting_asc{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==)
}
table.dataTable thead .sorting_desc{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=)
}
table.dataTable thead .sorting_asc_disabled{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAW0lEQVQoz2NgoCm4w3Vnwh02wspK7/y6k01Ikdadx3f+37l9RxmfIsY7c4GKQHDiHUbcyhzvvIMq+3THBpci3jv7oIpAcMcdduzKEu/8vPMdDn/eiWQYBYMKAAC3ykIEuYQJUgAAAABJRU5ErkJggg==)
}
table.dataTable thead .sorting_desc_disabled{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAWUlEQVQoz2NgGAWDCtyJvPPzznc4/HknEbsy9js77vyHw313eHGZZ3PnE1TRuzuOuK1lvDMRqmzuHUZ87lO+cxuo6PEdLUIeyb7z604pYf+y3Zlwh4u2YQoAc7ZCBHH4jigAAAAASUVORK5CYII=)
}
table.dataTable tbody tr{
    background-color:#ffffff
}
table.dataTable tbody tr.selected{
    background-color:#B0BED9
}
table.dataTable tbody th,table.dataTable tbody td{
    padding:8px 10px
}
table.dataTable.row-border tbody th,table.dataTable.row-border tbody td,table.dataTable.display tbody th,table.dataTable.display tbody td{
    border-top:1px solid #ddd
}
table.dataTable.row-border tbody tr:first-child th,table.dataTable.row-border tbody tr:first-child td,table.dataTable.display tbody tr:first-child th,table.dataTable.display tbody tr:first-child td{
    border-top:none
}
table.dataTable.cell-border tbody th,table.dataTable.cell-border tbody td{
    border-top:1px solid #ddd;
    border-right:1px solid #ddd
}
table.dataTable.cell-border tbody tr th:first-child,table.dataTable.cell-border tbody tr td:first-child{
    border-left:1px solid #ddd
}
table.dataTable.cell-border tbody tr:first-child th,table.dataTable.cell-border tbody tr:first-child td{
    border-top:none
}
table.dataTable.stripe tbody tr.odd,table.dataTable.display tbody tr.odd{
    background-color:#f9f9f9
}
table.dataTable.stripe tbody tr.odd.selected,table.dataTable.display tbody tr.odd.selected{
    background-color:#acbad4
}
table.dataTable.hover tbody tr:hover,table.dataTable.display tbody tr:hover{
    background-color:#f6f6f6
}
table.dataTable.hover tbody tr:hover.selected,table.dataTable.display tbody tr:hover.selected{
    background-color:#aab7d1
}
table.dataTable.order-column tbody tr>.sorting_1,table.dataTable.order-column tbody tr>.sorting_2,table.dataTable.order-column tbody tr>.sorting_3,table.dataTable.display tbody tr>.sorting_1,table.dataTable.display tbody tr>.sorting_2,table.dataTable.display tbody tr>.sorting_3{
    background-color:#fafafa
}
table.dataTable.order-column tbody tr.selected>.sorting_1,table.dataTable.order-column tbody tr.selected>.sorting_2,table.dataTable.order-column tbody tr.selected>.sorting_3,table.dataTable.display tbody tr.selected>.sorting_1,table.dataTable.display tbody tr.selected>.sorting_2,table.dataTable.display tbody tr.selected>.sorting_3{
    background-color:#acbad5
}
table.dataTable.display tbody tr.odd>.sorting_1,table.dataTable.order-column.stripe tbody tr.odd>.sorting_1{
    background-color:#f1f1f1
}
table.dataTable.display tbody tr.odd>.sorting_2,table.dataTable.order-column.stripe tbody tr.odd>.sorting_2{
    background-color:#f3f3f3
}
table.dataTable.display tbody tr.odd>.sorting_3,table.dataTable.order-column.stripe tbody tr.odd>.sorting_3{
    background-color:whitesmoke
}
table.dataTable.display tbody tr.odd.selected>.sorting_1,table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_1{
    background-color:#a6b4cd
}
table.dataTable.display tbody tr.odd.selected>.sorting_2,table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_2{
    background-color:#a8b5cf
}
table.dataTable.display tbody tr.odd.selected>.sorting_3,table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_3{
    background-color:#a9b7d1
}
table.dataTable.display tbody tr.even>.sorting_1,table.dataTable.order-column.stripe tbody tr.even>.sorting_1{
    background-color:#fafafa
}
table.dataTable.display tbody tr.even>.sorting_2,table.dataTable.order-column.stripe tbody tr.even>.sorting_2{
    background-color:#fcfcfc
}
table.dataTable.display tbody tr.even>.sorting_3,table.dataTable.order-column.stripe tbody tr.even>.sorting_3{
    background-color:#fefefe
}
table.dataTable.display tbody tr.even.selected>.sorting_1,table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_1{
    background-color:#acbad5
}
table.dataTable.display tbody tr.even.selected>.sorting_2,table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_2{
    background-color:#aebcd6
}
table.dataTable.display tbody tr.even.selected>.sorting_3,table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_3{
    background-color:#afbdd8
}
table.dataTable.display tbody tr:hover>.sorting_1,table.dataTable.order-column.hover tbody tr:hover>.sorting_1{
    background-color:#eaeaea
}
table.dataTable.display tbody tr:hover>.sorting_2,table.dataTable.order-column.hover tbody tr:hover>.sorting_2{
    background-color:#ececec
}
table.dataTable.display tbody tr:hover>.sorting_3,table.dataTable.order-column.hover tbody tr:hover>.sorting_3{
    background-color:#efefef
}
table.dataTable.display tbody tr:hover.selected>.sorting_1,table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_1{
    background-color:#a2aec7
}
table.dataTable.display tbody tr:hover.selected>.sorting_2,table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_2{
    background-color:#a3b0c9
}table.dataTable.display tbody tr:hover.selected>.sorting_3,table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_3{
    background-color:#a5b2cb
}
table.dataTable.no-footer{
        border-bottom:1px solid #111
}
table.dataTable.nowrap th,table.dataTable.nowrap td{
    white-space:nowrap
}
table.dataTable.compact thead th,table.dataTable.compact thead td{
    padding:4px 17px 4px 4px
}
table.dataTable.compact tfoot th,table.dataTable.compact tfoot td{
    padding:4px
}
table.dataTable.compact tbody th,table.dataTable.compact tbody td{
    padding:4px
}
table.dataTable th.dt-left,table.dataTable td.dt-left{
    text-align:left
}
table.dataTable th.dt-center,table.dataTable td.dt-center,table.dataTable td.dataTables_empty{
    text-align:center
}
table.dataTable th.dt-right,table.dataTable td.dt-right{
    text-align:right
}
table.dataTable th.dt-justify,table.dataTable td.dt-justifyw{
    text-align:justify
}
table.dataTable th.dt-nowrap,table.dataTable td.dt-nowrap{
    white-space:nowrap
}
table.dataTable thead th.dt-head-left,table.dataTable thead td.dt-head-left,table.dataTable tfoot th.dt-head-left,table.dataTable tfoot td.dt-head-left{
    text-align:left
}
table.dataTable thead th.dt-head-center,table.dataTable thead td.dt-head-center,table.dataTable tfoot th.dt-head-center,table.dataTable tfoot td.dt-head-center{
    text-align:center
}
table.dataTable thead th.dt-head-right,table.dataTable thead td.dt-head-right,table.dataTable tfoot th.dt-head-right,table.dataTable tfoot td.dt-head-right{
    text-align:right
}
table.dataTable thead th.dt-head-justify,table.dataTable thead td.dt-head-justify,table.dataTable tfoot th.dt-head-justify,table.dataTable tfoot td.dt-head-justify{
    text-align:justify
}
table.dataTable thead th.dt-head-nowrap,table.dataTable thead td.dt-head-nowrap,table.dataTable tfoot th.dt-head-nowrap,table.dataTable tfoot td.dt-head-nowrap{
    white-space:nowrap
}
table.dataTable tbody th.dt-body-left,table.dataTable tbody td.dt-body-left{
    text-align:left
}
table.dataTable tbody th.dt-body-center,table.dataTable tbody td.dt-body-center{
    text-align:center
}
table.dataTable tbody th.dt-body-right,table.dataTable tbody td.dt-body-right{
    text-align:right
}
table.dataTable tbody th.dt-body-justify,table.dataTable tbody td.dt-body-justify{
    text-align:justify
}
table.dataTable tbody th.dt-body-nowrap,table.dataTable tbody td.dt-body-nowrap{
    white-space:nowrap
}
table.dataTable,table.dataTable th,table.dataTable td{
    box-sizing:content-box
}
.dataTables_wrapper{
    position:relative;clear:both;*zoom:1;zoom:1
}
.dataTables_wrapper .dataTables_length{
    float:left
}
.dataTables_wrapper .dataTables_filter{
    float:right;text-align:right
}
.dataTables_wrapper .dataTables_filter input{
    margin-left:0.5em
}
.dataTables_wrapper .dataTables_info{
    clear:both;float:left;padding-top:0.755em
}
.dataTables_wrapper .dataTables_paginate{
    float:right;text-align:right;padding-top:0.25em
}
.dataTables_wrapper .dataTables_paginate .paginate_button{
    box-sizing:border-box;display:inline-block;min-width:1.5em;padding:0.5em 1em;margin-left:2px;text-align:center;text-decoration:none !important;cursor:pointer;*cursor:hand;color:#333 !important;border:1px solid transparent;border-radius:2px
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
    color:#333 !important;border:1px solid #979797;background-color:white;background:-webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));background:-webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);background:-moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);background:-ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);background:-o-linear-gradient(top, #fff 0%, #dcdcdc 100%);background:linear-gradient(to bottom, #fff 0%, #dcdcdc 100%)
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active{
    cursor:default;color:#666 !important;border:1px solid transparent;background:transparent;box-shadow:none
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover{
    color:white !important;border:1px solid #111;background-color:#585858;background:-webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));background:-webkit-linear-gradient(top, #585858 0%, #111 100%);background:-moz-linear-gradient(top, #585858 0%, #111 100%);background:-ms-linear-gradient(top, #585858 0%, #111 100%);background:-o-linear-gradient(top, #585858 0%, #111 100%);background:linear-gradient(to bottom, #585858 0%, #111 100%)
}
.dataTables_wrapper .dataTables_paginate .paginate_button:active{
    outline:none;background-color:#2b2b2b;background:-webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));background:-webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);background:-moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);background:-ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);background:-o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);background:linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);box-shadow:inset 0 0 3px #111
}
.dataTables_wrapper .dataTables_paginate .ellipsis{
    padding:0 1em
}
.dataTables_wrapper .dataTables_processing{
    position:absolute;top:50%;left:50%;width:100%;height:40px;margin-left:-50%;margin-top:-25px;padding-top:20px;text-align:center;font-size:1.2em;background-color:white;background:-webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));background:-webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);background:-moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);background:-ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);background:-o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);background:linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%)
}
.dataTables_wrapper .dataTables_length,.dataTables_wrapper .dataTables_filter,.dataTables_wrapper .dataTables_info,.dataTables_wrapper .dataTables_processing,.dataTables_wrapper .dataTables_paginate{
    color:#333
}
.dataTables_wrapper .dataTables_scroll{
    clear:both
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody{
    *margin-top:-1px;-webkit-overflow-scrolling:touch
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>td,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>th,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td{
    vertical-align:middle
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th>div.dataTables_sizing,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>td>div.dataTables_sizing,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>th>div.dataTables_sizing,.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td>div.dataTables_sizing{
    height:0;
    overflow:hidden;
    margin:0 !important;
    padding:0 !important
}
.dataTables_wrapper.no-footer .dataTables_scrollBody{
    border-bottom:1px solid #111
}
.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,.dataTables_wrapper.no-footer div.dataTables_scrollBody>table{
    border-bottom:none
}
.dataTables_wrapper:after{
    visibility:hidden;
    display:block;
    content:"";
    clear:both;
    height:0
}
@media screen and (max-width: 767px){
    .dataTables_wrapper .dataTables_info,.dataTables_wrapper .dataTables_paginate{
        float:none;
        text-align:center
    }
    .dataTables_wrapper .dataTables_paginate{
        margin-top:0.5em
    }
}
@media screen and (max-width: 640px){
    .dataTables_wrapper .dataTables_length,.dataTables_wrapper .dataTables_filter{
        float:none;
        text-align:center
    }
    .dataTables_wrapper .dataTables_filter{
        margin-top:0.5em
    }
}
table.dataTable tr.selected td, table.dataTable td.selected {
    background-color: #b0bed9 !important;
}
      
.dataTables_scrollBody .dataTables_sizing {
    visibility: hidden;
}
      
div.datatables {
    color: #333;
}

.container-fluid.crosstalk-bscols {
    margin-left: -30px;
    margin-right: -30px;
    white-space: normal;
}

body > .container-fluid.crosstalk-bscols {
    margin-left: auto;
    margin-right: auto;
}
.crosstalk-input-checkboxgroup .crosstalk-options-group .crosstalk-options-column {
    display: inline-block;
    padding-right: 12px;
    vertical-align: top;
}
@media only screen and (max-width:480px) {
    .crosstalk-input-checkboxgroup .crosstalk-options-group .crosstalk-options-column {
        display: block;
        padding-right: inherit;
    }
}